import React from 'react';
import { Helmet } from "react-helmet"
import NavigationBar from '../components/navigation_bar';
import * as cvStyles from '../components/cv.module.css';

export default function CV() {
    function Experience({children, location, title, timeRange}) {
        return <div>
            <h4 className={cvStyles.subsection}><b>{location}</b><i> - {title}</i></h4>
            <p className={cvStyles.mini}>{timeRange}</p>
            <ul className={cvStyles.text}>
                {children}
            </ul>
        </div>
    }

    function Project({children, name}) {
        return <div>
            <h4 className={cvStyles.subsection}><b>{name}</b></h4>
            <ul className={cvStyles.text}>
                {children}
            </ul>
        </div>
    }

    return <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>CV - Harry Zhou</title>
        </Helmet>
        <NavigationBar />

        <div className={cvStyles.content}>
            <h1 className={cvStyles.title}>CV</h1>
            <hr className={cvStyles.line} />
            <h2 className={cvStyles.header}>Harry Zhou</h2>
            <p className={cvStyles.mini}>
                1786 Iona Ave <br/>
                Naperville, IL 60565 <br/>
                (224) 806-6689 <br/>
                harryyz2@illinois.edu <br/>
            </p>

            <h3 className={cvStyles.section}>Education</h3>
            <h4 className={cvStyles.subsection}><b>University of Illinois at Urbana-Champaign</b></h4>
            <p className={cvStyles.mini}>Expected graduation: May 2025</p>
            <p className={cvStyles.mini}>Bachelor of Science in Mathematics and Computer Science</p>
            <p className={cvStyles.mini}>Minor in Physics</p>

            <h3 className={cvStyles.section}>Coursework</h3>
            <ul className={cvStyles.text}>
                <li><em>CS 225 - Data Structures and Algorithms</em> - A+</li>
                <li><em>CS 341 - System Programming</em> - A+</li>
                <li><em>CS 374 - Intro to Algorithms & Models of Computation</em> - A+</li>
                <li><em>CS 473 - Algorithms</em> - A</li>
                <li><em>CS 483 - Applied Parallel Programming</em> - A+</li>
                <li><em>CS 450 - Numerical Analysis</em> - A+</li>
                <li><em>CS 446 - Machine Learning</em> - A</li>
                <li><em>MATH 413 - Intro to Combinatorics</em> - A+</li>
                <li><em>MATH 447- Real Variables</em> - A</li>
                <li><em>MATH 461- Probability Theory</em> - A+</li>
                <li><em>PHYS 326 -  Classical Mechanics II</em> - A</li>
            </ul>

            <h3 className={cvStyles.section}>Technical Skills</h3>
            <p className={cvStyles.text}><b>Programming Languages:</b><br/>Java, C, C++, Python, HTML/CSS/JS, NodeJS, Go, Rust, GLSL, CUDA, Verilog, Bash Scripting</p>
            <p className={cvStyles.text}><b>Libraries/Tools/Systems:</b><br/>Linux, Git, Numpy, Scipy, PyTorch, Matplotlib, OpenGL, MySQL, Express.js, ReactJS, PyQt, Maven, Gradle, Make, Apache, Gatsby</p>

            <h3 className={cvStyles.section}>Experience</h3>
            <Experience location="Argonne National Laboratory" title="AI Research Intern" timeRange="May 2024 - Present">
                <li>Open-ended research internship studying compositional learning</li>
                <li>Implemented a method of fine-tuning a vision-language-action model using KTO alignment based on the method described in the paper</li>
                <li>Designed a data collection interface to efficiently label data in a way suitable for KTO training of the VLA model</li>
            </Experience>
            <Experience location="Argonne National Laboratory" title="Research Assistant" timeRange="September 2022 - May 2024">
                <li>Wrote the control system for a machine that uses magnetron sputtering for thin-film depositions</li>
                <li>Wrote data-collection software for lab equipment</li>
                <li>Designed and implemented data analysis software for x-ray reflectivity measurements on multilayer optics</li>
            </Experience>
            <Experience location="University of Illinois" title="Course Assistant" timeRange="January 2022 - December 2022">
                <li>Helped run a student-led course in Rust programming</li>
                <li>Provided guidance for students on homework and projects</li>
            </Experience>
            <Experience location="University of Illinois" title="Software Developer" timeRange="September 2024 - Present">
                <li>Worked on a team of 4 to implement analytics and data visualization features on an attendance app for a client</li>
                <li>Full-stack development: ReactJS frontend with modular, containerized docker backend servers</li>
            </Experience>
            
            <h3 className={cvStyles.section}>Projects</h3>
            <Project name="Personal Website">
                <li>Wrote a personal website from scratch with the React framework, optimized for static-loading with Gatsby</li>
                <li>Implemented various features that I use in my day-to-day life, such as a file hosting service</li>
                <li>Self-hosted with apache on a linux server</li>
            </Project>
            <Project name="Procedura">
                <li>Designed a framework for creating procedural structures in Minecraft</li>
                <li>Implemented a library in Golang for creating procedural structures and communicating them to a local server</li>
                <li>Wrote a Java plugin that dynamically registers and loads procedural creations to provide real-time feedback</li>
            </Project>
            <Project name="LLM Fine-Tuning">
                <li>Automated the collection of about a million chat messages from a game server I frequented, to serve as training data</li>
                <li>Fine-tuned Mistral 7b using LoRA adapters on a quantized base model (QLoRA)</li>
                <li>Training and data processing done in Pytorch, with Unsloth to accelerate training.</li>
                <li>Created a bot to interact with the people on the server, using the fine-tuned LLM</li>
                <li>Designed a data labelling interface to further refine the bot with KTO, using data from the bot’s interactions with real people.</li>
            </Project>
            <Project name="Neural Network from Scratch">
                <li>Designed and implemented a neural network machine learning library from scratch in c++</li>
                <li>No 3rd party libraries used</li>
                <li>Efficient backpropagation and optimizer implementation</li>
            </Project>
            <Project name="Sound Characterization Machine Learning Project">
                <li>A machine learning project to determine the pitch and timing of notes in a song that that consists of a known set of audio samples</li>
                <li>Based on the Basic Pitch paper from Spotify Research: (A Lightweight Instrument-Agnostic Model for Polyphonic Note Transcription and Multipitch Estimation)</li>
            </Project>
            <Project name="Card Game Server">
                <li>Game that can be played from my personal website, featuring an obscure card game called Eleusis</li>
                <li>Multiplayer functionality implemented with websocket programming, and certificates updated automatically with Cron</li>
                <li>Frontend made with React, and backend made with Express.js (NodeJS)</li>
            </Project>
            <Project name="SongPlayer">
                <li>A Minecraft mod that parses Midi files on the fly and converts them into a form that may be played in Minecraft</li>
                <li>Automatically places the blocks needed to play the song, and plays the music</li>
            </Project>
            <Project name="HBot">
                <li>A long-running project with over 20,000 lines of code</li>
                <li>A Minecraft bot that aggregates many features, such as building structures, playing music, Discord integration, etc.</li>
                <li>Made a derivative called EpsilonBot, which is primarily focused building images out of blocks</li>
            </Project>
            <Project name="Numerical Analysis of Projectile Trajectories in Minecraft">
                <li>Reverse-engineered Minecraft projectile physics, and produced mathematical model for all projectiles</li>
                <li>Uses Newton’s method to derive launch angles of projectiles with air resistance</li>
                <li>Created a write-up of the derivation and code on my blog</li>
            </Project>
            <Project name="Rust Raytracer">
                <li>A multithreaded raytracing engine written in Rust</li>
                <li>Uses path-tracing for global illumination</li>
            </Project>

            <h3 className={cvStyles.section}>Early (Pre-College) Projects</h3>
            <Project name="Character RNN">
                <li>Character-level LSTM-based neural network inspired by an article from Andrej Karpathy: “The Unreasonable Effectiveness of Recurrent Neural Networks”</li>
                <li>Trained on 500 MB of text extracted from Wikipedia</li>
                <li>Implemented in Tensorflow</li>
                <li>Used in a self-hosted bot that, when given a prompt by a user, would randomly sample a completion from the predicted distribution to approximate the style of speaking of a Wikipedia article</li>
            </Project>
            <Project name="CUDA Fractal Rendering Engine">
                <li>A configurable GPU-powered fractal animation renderer written in CUDA c++</li>
                <li>Produces a series of animation frames that can be compiled into a video with ffmpeg</li>
            </Project>
            <Project name="Simple Raytracer">
                <li>A straightforward raytracing engine that supports various materials and shapes, written in Java</li>
                <li>Supports various material types, volumetric shading, refraction for translucent materials, reflectivity based on the fresnel equations, etc.</li>
            </Project>
            <Project name="DLA Fractal Drawer">
                <li>A Java program that simulates diffusion-limited aggregation to produce visualizations of DLA aggregates</li>
            </Project>
            <Project name="GLSL Shader Programs">
                <li>Various shader programs written over the course of a few years</li>
                <li>Primarily various ray-traced demos, ray-marched 3D fractals, and IFS fractals</li>
                <li>These projects are how I learned most of what I know about today about rendering techniques and 3D graphics pipelines</li>
            </Project>

            <h3 className={cvStyles.section}>Awards and Honors</h3>
            <ul className={cvStyles.text}>
                <li>ICPC North American Qualifier (2023) - 4th among UIUC participants</li>
                <li>ICPC Mid-Central USA Regionals (2022) - 4th out of 74 mid-central USA teams</li>
                <li>Dean’s List, every semester (2021-2024)</li>
            </ul>
        </div>
    </div>;
}
