import React from 'react';
import { Helmet } from "react-helmet"
import NavigationBar from '../../components/navigation_bar';

export default function Test() {
    return <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Portfolio Testing</title>
        </Helmet>
        <NavigationBar />
    </div>
}