import React from 'react';
import { Helmet } from "react-helmet"
import NavigationBar from '../../components/navigation_bar';
import * as portfolioStyles from '../../components/portfolio/portfolio.module.css';

export default function Portfolio(props) {
    function Project({name, href, children}) {
        return <div>
            <h4 className={portfolioStyles.subsection}>
                {href
                    ? <a className={portfolioStyles.link} href={href}>{name}</a>
                    : name
                }
            </h4>
            <div className={portfolioStyles.project}>
                {children}
            </div>
        </div>
    }

    return <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Portfolio</title>
        </Helmet>
        <NavigationBar />

        <div className={portfolioStyles.content}>

            <h1 className={portfolioStyles.title}>Portfolio</h1>
            <hr className={portfolioStyles.line} />

            <h3 className={portfolioStyles.section}>Overview</h3>
            
            <p className={portfolioStyles.text}>
                <a className={portfolioStyles.link} href="/cv">Click here to see my CV</a><br/>
                This portfolio is a quick demo of a few of the projects that I have worked on.
                I'm still working on updating this, so look forward to seeing more projects in the future!
            </p>
            <hr className={portfolioStyles.thinline} />

            <h3 className={portfolioStyles.section}>Project Highlights</h3>
            
            <Project name="This Website">
                <p>This website is written from scratch with React and built with the Gatsby toolchain.
                I started working on it shortly before starting college, and continue update it from time to time.
                I sometimes also use this website as a sandbox for web-related projects, such as Eleusis or this simple file hosting server I wrote in node.
                This page in particular was originally written for my Math/CS application, but I am working on updating my portfolio with more recent projects and improved design.
                Nonetheless, feel free to take a look at the entries here.</p>
            </Project>

            <Project name="Eleusis" href="/eleusis">
                <p>A friend of mine who shares my love of math introduced to me a card game called Eleusis
                (<a className={portfolioStyles.link} href="https://en.wikipedia.org/wiki/Eleusis_(card_game)">instructions here</a>)
                that is heavily-based on mathematical reasoning and pattern recognition.
                Due to the pandemic however, we were unable to meet frequently in person to
                play. So, I developed a comprehensive online version of the game that hosts a party of up to eight friends so they can play
                virtually. It is in this way that I stay in touch with my friends; through my love of math and programming.</p>
                
                <p>Feel free to try it out in a group of 3 or more people and follow the hyperlink above for instructions!</p>
            </Project>

            <Project name="Numerical Analysis of Projectile Trajectories in Minecraft" href="https://blog.harryzhou.info/index.php/2021/04/01/minecraft-projectile-launch-angle-calculation/">
                <p>
                    Let's say you're shooting an arrow at a certain speed and you need to hit a target 100 meters away from you.
                    What angle do you need to shoot it at to hit the target?
                    When there's no air resistance, this differential equation can be solved for an exact solution.
                    This solution can actually be found on Wikipedia <a className={portfolioStyles.link} href="https://en.wikipedia.org/wiki/Projectile_motion#Angle_%CE%B8_required_to_hit_coordinate_(x,_y)">here</a>.
                </p> <p>
                    Unfortunately in real life we experience air resistance.
                    In fact, even things in Minecraft experience air resistance,
                    and when you have to account for air resistance, there's no longer
                    and analytical solution.
                </p> <p>
                    I was working on a feature (in HBot) that required the ability to
                    efficiently and precisely calculate the angle that any projectile needed
                    to hit a target. After much thinking, I devised a solution that used newton
                    approximation to converge on an answer extremely quickly, and wrote up
                    a blog article describing how I did it.
                </p>
            </Project>

            <Project name="Diffusion-Limited Aggregation Projects" href="/portfolio/dla">
                <p>
                    One particular phenomenon that I have written simulations for a few times
                    is called "Diffusion-Limited Aggregation", in which particles undergoing
                    Brownian motion aggregate together. It sounded like a lot of fun to write
                    simulations for this, so that's exactly what I ended up doing. <br/>
                    <a className={portfolioStyles.link} href="/portfolio/dla">Click here to read more about my project</a>
                </p>
            </Project>

            <Project name="HBot" href="https://github.com/hhhzzzsss/HBot-release">
                <p>
                    HBot is a Minecraft bot, but don't let that fact fool you.
                    With a code base that is now around 20,000 lines long,
                    HBot could possibly be considered the largest project that I have ever worked on.
                    After over a year of development, it now has
                    fully-fledged discord integration, a robust user-interface,
                    a custom-made cryptographic verification system, and tons of more commands
                    and features. There's an active community of users for it, and I'm still actively
                    developing it.
                </p> <p className={portfolioStyles.text}>
                    I like to see new people become interested in bot development too,
                    so I've made a release of the source code on Github for others to
                    use as a reference.
                    A few components needed for it to build are removed, however, to prevent
                    people from making exact copies of my bot.<br/>
                    <a className={portfolioStyles.link} href="https://github.com/hhhzzzsss/HBot-release">Here's my source code release for HBot</a>
                </p> <p className={portfolioStyles.text}>
                    Beyond that, I've made a derivative of one of its features in the form
                    of a Minecraft mod, called SongPlayer, which is currently my most-starred
                    repository on Github. It dynamically loads midi files and maps them onto Minecraft
                    instruments to play in real time. <br/>
                    <a className={portfolioStyles.link} href="https://github.com/hhhzzzsss/SongPlayer">Here's my Github repository for Songplayer</a>
                </p>
            </Project>

            <hr className={portfolioStyles.thinline} />

            <h3 className={portfolioStyles.section}>Why I Need Programming to Understand and Explore Math</h3>
            <p className={portfolioStyles.text}>
                I've been programming for a long time now, but over and over again,
                my projects seem to revolve around mathematics, and this is because math <i>needs</i> CS.
                Time and time again, I find that the best way to explore the mathematical topics
                that I'm interested in is to write programs about it.
                I've written programs about countless mathematical topics I find interesting,
                like complex-valued polynomials, Mersenne primes, Riemann Hypothesis,
                Taylor expansion, Collatz conjecture, and much more.
            </p> <p className={portfolioStyles.text}>
                But for the sake of my own time, I'll stick to talking about the subject
                that I am the most familiar with: Fractals.
            </p> <p className={portfolioStyles.text}>
                I already talked about fractals in my application, but it's hard to properly
                describe what I mean without the appropriate visuals
                Just look at this thing:
            </p>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Mandel_zoom_07_satellite.jpg/360px-Mandel_zoom_07_satellite.jpg" />
            <p className={portfolioStyles.text}>
                I love to learn about Chaos theory and study about these homeomorphic
                fractal functions, but the thing is,
                it's not humanly feasable to calculate or draw fractals like these
                by hand.
            </p> <p className={portfolioStyles.text}>
                And I'll be honest, although I find the proofs and derivations
                relating to fractals to be fascinating in their own respect, I
                would never have become as fascinated with fractals as I am now
                if I had never seen their visualizations, drawn by computers.
            </p> <p className={portfolioStyles.text}>
                Now, imagine if someone told you about the "orbit" of points in the mandebrot set.
                Someone could tell you about how it's the set of complex values z that are hit
                as you iterate a particular C through the equation z<sub>n+1</sub> = z<sub>n</sub>n<sup>2</sup>+C.
                While you can understand what that means, it's hard to really understand what would actually
                happen if you did that from the description alone. But if, for example, you wrote a program
                that illustrated it:
            </p>
            <iframe width="640" height="360" frameborder="0" src="https://www.shadertoy.com/embed/sdSBRm?gui=true&t=10&paused=true&muted=false" allowfullscreen></iframe>
            <p className={portfolioStyles.text}>
                The above frame may take a bit to load, but it's a program that I quickly put together
                as a recreation of the one I originally made when I was learning about Mandelbrot bifurcation patterns,
                since I don't have my original program anymore. <br />
                You can click or drag anywhere in the frame to see what the orbit looks like starting from that point.
            </p> <p className={portfolioStyles.text}>
                And suddenly, it makes an enormous difference in how easy the concept is to understand.
                Not only that, but you'll start noticing a lot more properties in the process, like that
                at the center of the cardioid bulb, the orbit converges to a single point, but in the
                circular bulbs, the orbit converges into a pattern that oscillates between several points.
                So many of these properties can not only be found but also better understood by making
                visualizations like these.
            </p> <p className={portfolioStyles.text}>
                And the fact of the matter is, I love writing programs like these that help me better understand
                mathematical topics. Whenever I see some new, interesting thing in math, I feel a strong desire
                to try it out myself, to play around with it and see what I get.
            </p> <p className={portfolioStyles.text}>
                Sure, visualizations and simulations like these aren't usually mathematically rigorous in any way,
                but rigor comes second. You need a conjecture before you can have a theorem.
            </p> <p className={portfolioStyles.text}>
                If you've ever seen 3Blue1Brown's videos, you'll notice his videos present
                advanced mathematical topics with an amazing level of clarity that make
                them extremely easy to understand. And at the center of it all is his
                fantastic visuals, all of which are programmed by himself. He has even
                made the mathematical animation library that he has developed open-source,
                which led to a plethora of fantastic mathematical and algorithmic visualizations
                created by all sorts of people.
            </p> <p className={portfolioStyles.text}>
                Whether you're communicating math, exploring a new topic, or trying to
                discover something new, good visualizations and simulations can offer great
                insights beyond what can be found with paper and pencil alone.
            </p>
            <hr className={portfolioStyles.thinline} />
            
            {/* <h3 className={portfolioStyles.section}>CS Interest</h3>
            <hr className={portfolioStyles.thinline} /> */}

        </div>
    </div>;
}
