import React from 'react';
import * as navStyles from './navigation_bar.module.css';

function Item(props) {
    let { label, href } = props;
    return <li className={navStyles.item}>
        <a className={navStyles.link} href={href}>{label}</a>
    </li>
}

export default class NavigationBar extends React.Component {
    render() {
        return <div className={navStyles.menu}>
            <ul className={navStyles.list}>
                <Item label='Home' href='/' />
                <Item label='Portfolio' href='/portfolio/main' />
                <Item label='CV' href='/cv' />
                <Item label='Blog' href='https://blog.harryzhou.info' />
                <Item label='Eleusis' href='/eleusis' />
            </ul>
        </div>;
    }
}