// extracted by mini-css-extract-plugin
export var content = "cv-module--content--8QBlB";
export var header = "cv-module--header--b18e3";
export var light = "cv-module--light--VS6dL";
export var line = "cv-module--line--Yiq-j";
export var link = "cv-module--link--eGUga";
export var mini = "cv-module--mini--u-3ev";
export var section = "cv-module--section--q6lXC";
export var subsection = "cv-module--subsection--wpN+y";
export var text = "cv-module--text--kD6UV";
export var title = "cv-module--title--bVufS";